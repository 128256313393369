<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><SelectAlunoTurma label="* Aluno:" :items="selectAluno" v-model="registro.id_aluno" :id_turma="this.id_turma" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'
import SelectAlunoTurma from "@/Views/Estrutura/Form/SelectAlunoTurma.vue";

export default {
    name: "TurmaForm",
    components: {SelectAlunoTurma},
    props : ['id_turma'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,

            selectAluno : []
        }
    },
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        salvar() {
            axios.post(`${this.baseUrl}turma/aluno_incluir`, {...this.registro, id_turma : this.id_turma}).then( (res) => {
                if(res.data.erro == true) {
                    this.erro = res.data.mensagem
                }
                else {
                    this.erro = null
                    this.$router.push({path : '/turma/gerenciar/' + this.id_turma})
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
